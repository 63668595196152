/* eslint-disable prettier/prettier */

import { Moralis } from "moralis";
import React, { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";

export default function Admin() {
    const contractid = "0xAB9BF0eeB5DE25D5734382489A8B63eb761A3e7d";
    const contractabi = require('../contracts/abi.json');
    const collectionName = "TomorrowsWorldNFT";
    const chainID = "1";

    const { isAuthenticated, provider, account } = useMoralis();
    const [price, setPrice] = useState("");
    const [presalePrice, setPresalePrice] = useState("");
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [theOwner, setTheOwner] = useState();
    const [privateMintActive, setPrivateMintActive] = useState();

    async function getData() {
        if (isAuthenticated && provider) {
            if (provider.networkVersion == chainID) {
                await Moralis.executeFunction({
                    functionName: "owner", abi: contractabi, contractAddress: contractid
                }).then(response => {
                    setTheOwner(response);
                });
                await Moralis.executeFunction({
                    functionName: "privateMintStarted", abi: contractabi, contractAddress: contractid
                }).then(response => {
                    setPrivateMintActive(response);
                });
                if (privateMintActive) {
                    await Moralis.executeFunction({
                        functionName: "presaleCost", abi: contractabi, contractAddress: contractid
                    }).then(response => {
                        setPresalePrice(Moralis.Units.FromWei(response.toString()));
                    });
                } else {
                    await Moralis.executeFunction({
                        functionName: "cost", abi: contractabi, contractAddress: contractid
                    }).then(response => {
                        setPrice(Moralis.Units.FromWei(response.toString()));
                    });
                }

                if (!isDataLoaded) {
                    setIsDataLoaded(true);
                }
            }
        }
    }

    useEffect(() => {
        if (account && theOwner) {
            if (account.toLowerCase() != theOwner.toLowerCase()) {
                window.location.href = '/mint/';
            }
        }
        if (!isDataLoaded) {
            getData();
        }
    });

    return (
        <div
            style={{
                margin: "20px auto 0",
                display: "flex",
                gap: "20px",
                marginTop: "25",
                width: "80vw",
                flexWrap: "wrap",
                background: "#c7c7c7",
                padding: "5px 10px 15px"
            }}
        >
            <h2 style={{
                textAlign: "center",
                flex: "1 0 100%",
                paddingBottom: "20px",
                fontSize: "34px"
            }}>Admin Panel For {collectionName}</h2>
            <div
                style={{
                    margin: "auto",
                    display: "flex",
                    gap: "20px",
                    marginTop: "25",
                    flexWrap: "wrap"
                }}
            >
                <div className="admin-controls">
                    <div className="control-box">
                        <p>Public Sale Price: {price}</p>
                        <input id="changePublicPriceValue" type="text" />
                        <button id="changePublicPrice" onClick={async () => {
                            let newPrice = document.querySelector('#changePublicPriceValue').value;

                            await Moralis.executeFunction({
                                functionName: "setMintPrice",
                                abi: contractabi,
                                contractAddress: contractid,
                                params: {
                                    value: Moralis.Units.ETH(newPrice)
                                }
                            });

                        }}>Change Price</button>
                    </div>
                    <div className="control-box">
                        <p>Presale Price: {presalePrice}</p>
                        <input id="changePresalePriceValue" type="text" />
                        <button id="changePresalePrice" onClick={async () => {
                            let newPrice = document.querySelector('#changePresalePriceValue').value;

                            await Moralis.executeFunction({
                                functionName: "setPresalePrice",
                                abi: contractabi,
                                contractAddress: contractid,
                                params: {
                                    value: Moralis.Units.ETH(newPrice)
                                }
                            });

                        }}>Change Price</button>
                    </div>
                    <div className="control-box">
                        <p>Public Mint: {privateMintActive ? ('Not Active') : ('Active')}</p>
                        <button id="changePublicMint" onClick={async () => {
                            if (confirm("This will disable the whitelist and cannot be undone, are you sure you wish to proceed?") == true) {
                                await Moralis.executeFunction({
                                    functionName: "startPublicMint",
                                    abi: contractabi,
                                    contractAddress: contractid
                                });
                            }
                        }} style={{ margin: "0 auto", display: "block" }}>Activate Public Mint</button>
                    </div>
                    <div className="control-box">
                        <p>Mint</p>
                        <div>
                            <input id="mintValue" type="text" />
                            <button id="doMint" onClick={async () => {
                                let mintQuanity = document.querySelector('#mintValue').value;

                                await Moralis.executeFunction({
                                    functionName: "devMint",
                                    abi: contractabi,
                                    contractAddress: contractid,
                                    params: {
                                        quantity: mintQuanity
                                    }
                                });

                            }}>Mint</button>
                        </div>
                    </div>
                    <div className="control-box" style={{ width: "100%" }}>
                        <p>IPFS Update</p>
                        <div style={{ marginBottom: "10px" }}>
                            <input id="changeBaseIPFSValue" type="text" />
                            <button id="changeBaseIPFS" onClick={async () => {
                                let newBaseUri = document.querySelector('#changeBaseIPFSValue').value;

                                await Moralis.executeFunction({
                                    functionName: "setBaseURI",
                                    abi: contractabi,
                                    contractAddress: contractid,
                                    params: {
                                        _newBaseURI: newBaseUri
                                    }
                                });

                            }}>Change Base IPFS Link</button>
                        </div>
                        <div>
                            <input id="changeNotRevealedValue" type="text" />
                            <button id="changeNotRevealed" onClick={async () => {
                                let newNotRevealed = document.querySelector('#changeNotRevealedValue').value;

                                await Moralis.executeFunction({
                                    functionName: "setNotRevealedURI",
                                    abi: contractabi,
                                    contractAddress: contractid,
                                    params: {
                                        _notRevealedURI: newNotRevealed
                                    }
                                });

                            }}>Change Not Revealed Link</button>
                        </div>
                    </div>
                    <div className="control-box" style={{ width: "100%" }}>
                        <p>Change Merkle</p>
                        <div>
                            <input id="merkleTreeValue" type="text" />
                            <button id="changeMerkleTree" onClick={async () => {
                                let newMerkleRoot = document.querySelector('#merkleTreeValue').value;

                                await Moralis.executeFunction({
                                    functionName: "setPresaleMerkleRoot",
                                    abi: contractabi,
                                    contractAddress: contractid,
                                    params: {
                                        value: newMerkleRoot
                                    }
                                });

                            }}>Change Merkletree Root</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}